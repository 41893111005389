import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DropDown = ({ className, title, children}) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Dropdown
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            show={isHovered}
        >
            <Dropdown.Toggle className={className}>
            { title }
            </Dropdown.Toggle>
            <Dropdown.Menu>
            {
                React.Children.map(children, (child) => {
                    return (
                        <Dropdown.Item>
                        {
                            child
                        }
                        </Dropdown.Item>
                    )
                })
            }
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default DropDown;