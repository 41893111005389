import { Col, Container, Row } from "react-bootstrap";
import "../css/footer.css";
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import ContactModal from '../components/ContactModal';
import MailerModal from '../components/MailerModal';
import { customGetApiCall } from "../misc/helper";

const Footer = () => {

    const [show, setShow] = useState(false);
    const [showMailer, setShowMailer] = useState(false);
    const [links, setLinks] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseMailer = () => setShowMailer(false);
    const handleShowMailer = () => setShowMailer(true);

    useEffect(() => {
        const fetchLinks = async () => {
            let links = await (await customGetApiCall(`/file/type/cgv`)).data.files;
            links = links.map(link => link.link)
            setLinks(links)
        }
        if (links == null)
            fetchLinks()
    }, []);

    return (
        <Container className="footerContainer">
            <Row>
                <Col xs={{span: 6}} md={{ span: 2, offset: 2 }}>
                    <Col className="titleHeader headerLineTitle">PRESTATIONS</Col>
                    <Col className="headerLine footerLink"> <Link to="/expertise">GemmIdentification </Link> </Col>
                    <Col className="headerLine footerLink"> <Link to="/formation">GemmEducation</Link> </Col>
                    <Col className="headerLine footerLink"> <Link to="/artistique">GemmArtistic</Link> </Col>
                </Col>
                <Col xs={{span: 6}} md={ 2 }>
                    <Col className="titleHeader headerLineTitle">LEGAL</Col>
                    <Col className="headerLine footerLink"><a href={ links ? links[0] : ""} rel="noopener noreferrer" target="_blank">CGV Formation</a></Col>
                    <Col className="headerLine footerLink"><a href={ links ? links[1] : ""} rel="noopener noreferrer" target="_blank">CGV Expertise</a></Col>
                    <Col className="headerLine footerLink"><a href={ links ? links[2] : ""} rel="noopener noreferrer" target="_blank">CGV Artistique</a></Col>
                    <Col className="headerLine footerLink"><a href={ links ? links[3] : ""} rel="noopener noreferrer" target="_blank">Mentions Légales</a></Col>
                </Col>
                <Col xs={{span: 6}} md={{ span: 2 }}>
                    <Col className="titleHeader headerLineTitle">SOCIAL</Col>
                    <Col className="headerLine footerLink"><a href="https://www.instagram.com/marine_bouvier_gemmartistic/" rel="noopener noreferrer" target="_blank">Instagram</a></Col>
                    <Col className="headerLine footerLink"><a href="https://www.facebook.com/MarineBouvierGemmartistic" rel="noopener noreferrer" target="_blank">Facebook</a></Col>
                    <Col className="headerLine footerLink"><a href="https://www.linkedin.com/in/marine-bouvier-gemmology/" rel="noopener noreferrer" target="_blank">LinkedIn</a></Col>
                </Col>
                <Col xs={{span: 6}} md={{ span: 2 }}>
                    <Col className="titleHeader headerLineTitle">PLUS D'INFOS</Col>
                    <Col className="headerLine footerLink" onClick={handleShowMailer}> Gemsletter </Col>
                    <Col className="headerLine footerLink"><a href="https://www.etsy.com/fr/shop/Gemmartistic" rel="noopener noreferrer" target="_blank">Boutique en ligne</a></Col>
                    <Col className="headerLine footerLink" onClick={handleShow}>Contact</Col>
                </Col>
            </Row>
            <ContactModal show={show} handleClose={handleClose} />
            <MailerModal show={showMailer} handleClose={handleCloseMailer} />
        </Container>
    )
}

export default Footer;