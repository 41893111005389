import { Route, HashRouter as Router, Routes } from 'react-router-dom';
import Home from "./page/Home";
import Login from "./page/LoginAdmin";
import Admin from './page/Admin';
import { ToastContainer } from 'react-toastify';
import Header from './layout/Header';
import Footer from './layout/Footer';
import ExpertisePage from './page/ExpertisePage';
import EducationPage from './page/EducationPage';
import ArtisticPage from './page/ArtisticPage';
import GalleryPage from './page/GalleryPage';
import { useRef } from 'react';
import WhoAmI from './page/WhoAmI';

function App() {

  const sizeHeader = useRef(null);

  // window.addEventListener("contextmenu", e => e.preventDefault());

  return (
    <Router>

      <Header
        sizeHeader={sizeHeader}
      />
      
      <Routes>
        <Route path="/" element={<Home sizeHeader={sizeHeader} />}/>
        <Route path="/expertise" element={<ExpertisePage />}/>
        <Route path="/formation" element={<EducationPage />}/>
        <Route path="/artistique" element={<ArtisticPage />}/>
        <Route path="/galerie" element={<GalleryPage />}/>
        <Route path="/about" element={<WhoAmI />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/private" element={<Admin />}/>
      </Routes>

      <Footer />

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
}

export default App;
