
import { useState } from 'react';
import { Container, Navbar, Nav, Row, Col, NavDropdown, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../asset/img/logoSolo.png';
import DropDown from "../components/DropDown";
import "../css/header.css";
import ContactModal from '../components/ContactModal';

const Header = ({ sizeHeader }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Navbar sticky="top" expand="sm" className='navbarContent ml-auto' ref={sizeHeader}>
            <Container>
                <Col href="#home">
                    <Link className='align-for-mobile navLinkStyle' to="/">
                        <img src={logo} className="logoSolo" alt="logo" />
                    </Link>
                </Col>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="m-auto">
                        <Nav.Link as={Link} className='align-for-mobile navLinkStyle' to="/">Accueil</Nav.Link>
                        <Nav.Link as={Link} className='align-for-mobile navLinkStyle' to="/about">Qui suis-je</Nav.Link>
                        <DropDown className='btn-hide' title="Prestations">
                            <Link className='align-for-mobile navLinkStyle' to="/expertise">GemmIdentification</Link>
                            <Link className='align-for-mobile navLinkStyle' to="/formation">GemmEducation</Link>
                            <Link className='align-for-mobile navLinkStyle' to="/artistique">GemmArtistic</Link>
                        </DropDown>
                        <Nav.Link as={Link} className='align-for-mobile navLinkStyle' to="/galerie">Galerie</Nav.Link>
                        <Nav.Link className='align-for-mobile navLinkStyle' onClick={handleShow}>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
            <ContactModal show={show} handleClose={handleClose} />
        </Navbar>
    );
}

    export default Header;