import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { customGetApiCall } from "../misc/helper";
import "../css/blog.css"
import { BACK_URL } from "../misc/env";

const Post = ({ img, text, url }) => {

    return (
        <Row className="blog">
            <Col xs={12} md={3} className="postContainer">
                {
                    url == ""
                     ? <img style={{ borderRadius: 5}} src={img} className="post" />
                     : <a style={{ borderRadius: 5}} href={url} rel="noopener noreferrer" target="_blank">
                            <img src={img} className="post" />
                        </a>
                }
                
            </Col>
            <Col xs={12} md={9} className="blogText" dangerouslySetInnerHTML={{__html: text}}>
            </Col>
        </Row>
    )
}

const Blog = () => {

    const [blog, setBlog] = useState(null);
    const [pagination, setPagination] = useState(0);
    const [nbPages, setNbPages] = useState(0);

    useEffect(() => {
        if (!blog) {
            const fetchBlog = async () => {
                const blog = await customGetApiCall(`/file/type/blog`)
                const blogs = blog.data.files.reverse();
                setNbPages(Math.ceil(blogs.length / 3))
                setBlog(blog.data.files)
            }
            fetchBlog()
        }
    });

    const handlePagination = () => {
        let pagin = Array.from(Array(nbPages).keys())

        return pagin.map((p, i) => {
            if (i == pagination) {
                return <Col key={i} className="pagActive">{i + 1}</Col>
            } else {
                return <Col key={i} className="pag" onClick={() => {setPagination(i)}}>{i + 1}</Col>
            }
        })
    }


    return (
        <Container>
            {/* <Row className="line"></Row> */}
            <div className="titleBlog"> Mon actualité </div>
            <Row className="containerBlog">
                {
                    blog && blog.map((post, index) => {
                        if (index >= pagination * 3 && index < (pagination + 1) * 3) {
                            const path = `${BACK_URL}/images/${post.path}`;

                            return (
                                <Post key={index} img={path} text={post.description} url={post.link} />
                            )
                        }
                    })
                }
                <div className="containerPagin">
                    <Row>
                    {
                        handlePagination()
                    }
                    </Row>
                </div>
            </Row>
        </Container>
    )
}

export default Blog;