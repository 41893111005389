import { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import Zoom from "../components/Zoom";

import buy from "../asset/img/buy.svg";

const Image = ({ path, index, description, link }) => {

    return (
        <>
            <Col className="uploadedImgContainer" xs={6} md={3} key={path}>
                <Zoom textToDisplay={description}>
                    <img className="uploadedImg" key={index} src={path} alt=""/>
                </Zoom>
                {
                    link
                    ? <div className="buyContainer"> <a href={link}  rel="noopener noreferrer" target="_blank"><img className="buyImg" src={buy} alt=""/> </a></div>
                    : null
                }
                
            </Col>
        </>
    )
}

export default Image;