
import { useEffect, useState } from "react";
import UniversImage from "../components/UniversImage";
import { isMobile } from "react-device-detect";
import { Col, Container, Row } from "react-bootstrap";
import "../css/home.css"
import { Link } from "react-router-dom";

import iconFacebook from "../asset/img/iconFacebook.svg";
import iconInstagram from "../asset/img/iconInstagram.svg";
import iconLinkedin from "../asset/img/iconLinkedin.svg";
import { customGetApiCall } from "../misc/helper";

import { BACK_URL } from "../misc/env";
import Blog from "../components/Blog";

const Card = ({ title, img, subtitle, link }) => {
    return (
        <Col xs={{ span: 12, offset: 0 }} md={{span: 8, offset: 2}} lg={{ span: 4, offset: 0}}>
            <Row className="titleUnivers"><Col>{ title }</Col></Row>
            <Row>
                <Col className="containerUniversImage">
                    <Link to={link}>
                        <img src={img} alt="UniversImage" className="UniversImage" />
                    </Link>
                </Col>
            </Row>
            <Row><Col className="subTitleUnivers">{ subtitle }</Col></Row>
        </Col>
    )
}

const Home = ({ sizeHeader }) => {

    const [rand, setRand] = useState(null);
    const [frontImg, setFrontImg] = useState(null);
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

    let resizeTimeout;
    let sizeHeaderHeight = sizeHeader.current ? sizeHeader.current.clientHeight : 0;
    sizeHeaderHeight = isMobile ? 0 : sizeHeaderHeight;
    let width = isMobile ? "auto" : windowSize[0] + "px";
    let height = isMobile ? "auto" : windowSize[1] + "px";

    useEffect(() => {
        if (rand == null) {
            const fetchImg = async () => {
                const img = await customGetApiCall(`/file/type/home`)
                const length = img.data.files.length;
                const r = Math.floor(Math.random() * length)
                const path = `${BACK_URL}/images/${img.data.files[r].path}`;
                setRand(r);
                setFrontImg(path);
            }
            fetchImg()
        }

        const handleResize = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            }, 250);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.scrollTo(0, 0);
        }
    }, [rand])

    return (
        <div>
            <div style={{ maxWidth: width, maxHeight: height, overflow: "hidden", marginTop: `${ - sizeHeaderHeight}px`, position: "relative"}}>
                <img src={frontImg} alt="frontImage" className="frontImage" />
                <div className="overlayTitle" style={{ top: `${sizeHeaderHeight}px`}}>
                    <Row className="nameTitle">Marine Bouvier</Row>
                    <Row className="jobTitle">Experte-Gemmologue</Row>
                    <Row>
                        <Col>
                            <a href="https://www.facebook.com/MarineBouvierGemmartistic" rel="noopener noreferrer" target="_blank">
                                <img src={iconFacebook} className="iconSocialNetwork" />
                            </a>
                        </Col>
                        <Col>
                            <a href="https://www.instagram.com/marine_bouvier_gemmartistic/" rel="noopener noreferrer" target="_blank">
                                <img src={iconInstagram} className="iconSocialNetwork" />
                            </a>
                        </Col>
                        <Col>
                            <a href="https://www.linkedin.com/in/marine-bouvier-gemmology/" rel="noopener noreferrer" target="_blank">
                                <img src={iconLinkedin} className="iconSocialNetwork" />
                            </a>
                        </Col>
                    </Row>
                </div>
            </div>
            <Container className="mainContainer">
                <Row className="containerMainTitleUnivers">
                    <Col className="mainTitleUnivers">Entrez dans l'univers des pierres précieuses</Col>
                    <Col>
                        <Col md={{ span: 10, offset: 1}} className="textMainTitle ">
                            La gemmologie est la science de l’identification des pierres précieuses, fines et ornementales.
                            Le gemmologue, quant à lui, est l’expert.e qui identifie et dévoile les secrets des gemmes, des plus communes au plus rares.<br /><br />
                            Diplômée &amp; passionnée de gemmologie depuis plusieurs années, j’ai à cœur de mettre à votre disposition mes différentes compétences au travers de trois univers, celui de l’expertise, la formation et la vente de tableaux précieux.<br /><br />
                            Je vous invite à les découvrir ci-dessous ou dans l’onglet « Prestations »
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Card
                        title="GemmIdentification"
                        img={UniversImage[0]}
                        subtitle="L'univers de l'analyse des pierres précieuses, fines et ornementales"
                        link="/expertise"
                    />
                    <Card 
                        title="GemmEducation" 
                        img={UniversImage[1]} 
                        subtitle="L'univers de l'apprentissage de la gemmologie"
                        link="/formation"
                    />
                    <Card 
                        title="GemmArtistic" 
                        img={UniversImage[2]} 
                        subtitle="L'univers artistique des tableaux precieux"
                        link="/artistique"
                    />
                </Row>
                <Row>
                    <Blog />
                </Row>
            </Container>
            
        </div>
    );
};


export default Home;