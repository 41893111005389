import { useEffect, useRef, useState } from "react";
import { customGetApiCall } from "../misc/helper";
import { BACK_URL } from "../misc/env";
import { Col, Container, Form, Row } from "react-bootstrap";
import ContactModal from '../components/ContactModal';
import { Link } from 'react-router-dom';

import Image from "../components/Image";
import { isMobile } from "react-device-detect";

const path = `${BACK_URL}/images/`;
let timeout = null;
const Gallery = () => {

    const [img, setImg] = useState([]);
    const [render, setRender] = useState(false);
    const [renderImg, setRenderImg] = useState(false);
    const [query, setQuery] = useState("?search=");

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let width = isMobile ? "50%" : "25%";

    const fetchImg = async (query) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            setRenderImg(true);
        }, 1000);
        const data = await customGetApiCall(`/file/type/gallery` + query)
        setImg(data.data.files);
        setRender(true);
    }

    useEffect(() => {
        if (!render){
            fetchImg(query);
        }

    }), [img, renderImg, query, render];

    

    const handleSearchBar = async (e) => {
        setQuery("?search=" + e.target.value)
        setRenderImg(false);
        setRender(false)
    }


    return (
        <Container>
            <Row>
                {/* Sous titre */}
                <Col style={{ padding: "0px 30px", textAlign: "center", fontSize: 21 }}>
                    Bienvenue dans la Galerie d’Art virtuelle de photomicrographies d’inclusions dans les pierres précieuses.
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col style={{ padding: "0px 30px" }}>
                    Cette galerie vous propose un <b>accès gratuit et illimité à de nombreuses photographies d’inclusions hautement qualitatives</b> et réalisées avec un matériel optique professionnel.
                    Chacune de ces photos est <b>décrite de façon claire et précise afin de vous aider au mieux</b> dans votre apprentissage de la gemmologie liée à l’identification des pierres précieuses, fines et ornementales. 
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col style={{ padding: "0px 30px" }}>
                    <b>Chacune des photos proposées dans cette galerie est imprimable sur demande en tableau précieux</b> (à découvrir <Link className="linkArtistic" to="/artistique" target="_blank" rel="noopener noreferrer" >ici</Link>)
                    Pour en commander un, il vous suffit de <b>nous contacter</b> à l’aide du bouton ci-dessous ou de l’onglet « Contact », en nous précisant le numéro de la photo (écrit dans la description) et la dimension voulue. Nous vous proposerons alors un devis personnalisé à vos attentes dans les meilleurs délais ainsi qu’un accompagnement tout au long de votre projet.
                </Col>
            </Row>
            <Row>
                <Col style={{ padding: "0px 30px", textAlign: "center" }}>
                    <button onClick={handleShow} className="btn btn-primary">COMMANDER UN TABLEAU</button>
                </Col>
            </Row>
            <br></br>
            <Row >
                <Form.Group className="mb-3" controlId="formBasicEmail" style={{ padding: "0px 30px" }}>
                    <Form.Control type="email" placeholder="Vous cherchez quelque chose de précis ?" onChange={handleSearchBar}/>
                </Form.Group>
            </Row>
            <Row className={ renderImg ? 'visibilityCustom' : '' } style={{padding: 15}}>
                {
                    img.map((item, index) => {
                        return (
                            <Col className="containerPlaceholder" key={"placeholder" + index} xs={6} md={3} style={{ paddingTop: width }}>
                                <div className="placeholderCustom animated-background ">

                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
            <Row className={ !renderImg ? 'visibilityCustom' : '' } style={{padding: 15}}>
            {
                img.map((file, index) => {
                    return (
                        <Image
                            path={`${path}${file.path}`}
                            index={index}
                            description={file.description}
                            link={file.link}
                            key={index}
                        />
                    )
                })
            }
            </Row>
            <Row> 
                {/* Mettre dans le footer */}
                <Col style={{ padding: "20px 30px", color: "gray", fontSize: 12 }}>
                    Remarque : Chacune de ces images est la propriété exclusive de Marine Bouvier et toute utilisation, impression ou reproduction, même partielle, sans l'autorisation écrite expresse de Marine Bouvier est strictement interdite. Cela inclut les publications sur des sites web ou les réseaux sociaux. Si vous souhaitez utiliser une de ces images, merci de nous contacter.
                </Col>
            </Row>
            <ContactModal show={show} handleClose={handleClose} />
        </Container>
    )
}

export default Gallery;

