import React, { useCallback, useState } from 'react'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import "../css/zoom.css"

const Zoom = ({ children, textToDisplay }) => {
  const [isZoomed, setIsZoomed] = useState(false)

  const handleImgLoad = useCallback(() => {
    setIsZoomed(true)
  }, [])

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom)
  }, [])

  return (
    <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
      {
        children
      }
      {
        isZoomed
            ? <div className='zoomText'>{textToDisplay}</div>
            : null 
      }
    </ControlledZoom>
  )
}

export default Zoom