
import { Button, Col, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { BACK_URL } from "../misc/env";

const Admin = () => {

    const [userName, setUserName] = useState("");
    const [passWord, setPassWord] = useState("");

    const getToken = () => {
        axios.post(`${BACK_URL}/auth/connect`, {user_name : userName, password : passWord}).then(res => {
            let token = res.data.token;
            sessionStorage.setItem("jwt", token);
            window.location.href = "/#/private";
        })
        .catch(err => {
            switch (err.response.status) {
                case 403: toast.error("Mot de passe incorrect"); break ;
                case 404: toast.error("Utilisateur non trouvé"); break ;
                case 500: toast.error("Erreur interne"); break ;
            }
        })
    }

    return (
        <div style={{ height: "100vh" }}>
            <h1>Login</h1>
            <Row>
                <Col xs={3} xl={4}/>
                <Col xs={6} xl={4}>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>User name</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={(e) => { setUserName(e.target.value)}}/>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={(e) => { setPassWord(e.target.value)}}/>
                        </Form.Group>
                        <Button variant="primary" onClick={getToken}>Connexion</Button>
                    </Form>
                </Col>
                <Col xs={3} xl={4}/>
            </Row>
            
        </div>
    );
};


export default Admin;