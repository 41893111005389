
import axios from "axios";

import { BACK_URL } from "../misc/env";

const getHeader = (isImg = false) => {
    const config = {
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("jwt")
        }
    };

    if (isImg) {
        config.headers['Content-Type'] = 'multipart/form-data';
    }

    return config;
}

const givePermission = (callbackError) => {
    if (sessionStorage.getItem("jwt") === null) {
        window.location.href = "/#/login";
    }
    else {
        const config = getHeader();

        axios.get(`${BACK_URL}/auth/isConnected`, config).then(res => {
            sessionStorage.setItem("jwt", res.data.token);
        }).catch(err => {
            callbackError(err);
        });
    }
}

const customGetApiCall = (url) => {
    const config = getHeader();

    return axios.get(`${BACK_URL}${url}`, config)
}

const customPostApiCall = (url, data, isImg) => {
    const config = getHeader(isImg);

    return axios.post(`${BACK_URL}${url}`, data, config)
}

const customPutApiCall = (url, data, isImg) => {
    const config = getHeader(isImg);

    return axios.put(`${BACK_URL}${url}`, data, config)
}

const customDeleteApiCall = (url) => {
    const config = getHeader();

    return axios.delete(`${BACK_URL}${url}`, config)
}

export {
    givePermission, 
    customGetApiCall,
    customPostApiCall,
    customPutApiCall,
    customDeleteApiCall
};