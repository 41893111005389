import { Container, Row, Col, Button } from "react-bootstrap";
import banniere from "../asset/img/expertise/banniereExpertise.jpg";
import "../css/expertisePage.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ContactModal from "../components/ContactModal";
import { isMobile } from "react-device-detect";

const ExpertisePage = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        return () => {
            window.scrollTo(0, 0);
        }
    }, [])

    return (
        <Container style={{ padding: 0, background: 'white' }} className="mainContainer">

                <Row style={{ position: "relative", margin: 0, marginBottom: 30 }} className="imgBanniere">
                    <img src={banniere} alt="ExpertiseImage" className="expertiseImage" />
                    <div className="overlayExpertise">
                        Expertise
                    </div>
                </Row>


                <Row>
                    <Col className="textExpertiseTitle" md={{  span: 10, offset: 1}} xs={{ span: 10, offset: 1}}>
                        <p> L’expertise gemmologique est essentielle afin de réaliser un achat en toute conscience, une vente en restant conforme au regard de la loi, mais également pour évaluer correctement la valeur de vos pierres.</p>
                    </Col>
                    <Col className="textExpertise" md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1}}>
                        <p> Étant formée à l’identification des pierres de couleur et diamants depuis plusieurs années, j’ai à cœur de vous proposer une <b>expertise gemmologique de qualité</b> afin de vous permettre de savoir ce que vous possédez et <b>pouvoir le faire valoir</b> auprès de vos futurs clients, votre assurance ou tout simplement pour vous-même.</p>
                    </Col>
                    <Col className="textExpertise" md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1}}>
                        <p> Je délivre des <b>rapports d’analyses gemmologiques</b> de vos pierres avec <b>rigueur et professionnalisme</b>, tout en vous promettant une <b>transparence des résultats et une confidentialité de la démarche</b>. Une mise à jour de mes connaissances, un matériel neuf et adapté et de nombreuses sources de références me permettent de délivrer un <b>travail de confiance et pertinent</b>.</p>
                    </Col>
                </Row>

                <Row>
                    <Col md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }} className="containerPrice">
                    
                        <div className="titleList">Le forfait d’une heure d’analyse gemmologique vous est proposé au tarif de <span className="underlineText">180 € TTC de l'heure</span>, cela inclut :</div>
                        <ul>
                            <li> La détermination des critères suivants : la nature (la variété), la couleur, le poids, la forme et dimensions, les éventuelles synthèses ou traitements (voir conditions dans les « CGV Expertise »).</li>
                            <li> L’expertise de <span className="underlineText">1 à 4 pierres maximum par heure</span>,réalisé <b>sur place</b> </li>
                            <li> La rédaction du <b>rapport d’analyse gemmologique</b> remis sous <b>5 jours ouvrés</b> maximum (dont une copie sera conservée par l’expert).</li>
                            <li> Un <b>rapport d'analyse gemmologique oral</b> (sans délivrance de rapport d’analyse écrit) pourra être réalisé sur demande pour un tarif de <b>40 € TTC</b> par pierre expertisée</li>
                        </ul>

                    </Col>
                </Row>

                {
                    isMobile
                        ? null
                        : <Row style={{ background: 'black', margin: 0, marginBottom: 30}}>
                            <div className="mainParalax2" />
                        </Row>
                }

                <Row style={{ marginBottom: 30, textAlign: "center"}}>
                    <Col md={{ span: 8, offset: 2 }} xs={{ span: 8, offset: 2}}>
                        <div>Pour plus de confidentialité et de sécurité, <b>je me déplace avec l’ensemble de mon matériel</b> à votre domicile ou sur votre lieu de travail, atelier etc. sur Nice (un forfait supplémentaire de déplacement sera demandé pour toutes autres localités).</div><br/>
                        <div>La gradation du diamant, l’analyse des perles et des matières organiques seront réalisées si besoin en collaboration avec un <b>laboratoire de gemmologie</b> reconnu et qualifié (voir détails auprès de nos « CGV Expertise »).</div><br/>
                        <div><b>L’analyse se réalise en une fois, aucune pierre n’est récupérée ni conservée par l’expert.</b></div>
                    </Col>
                </Row>

                <Row>
                    <Col md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1}} style={{ marginBottom: 35 }}>
                        <Row>
                            <Col style={{ textAlign: "center" }}>
                                <Button className="buttonWhoami" onClick={handleShow}> Demander un devis ou des informations </Button>
                            </Col>
                            <Col style={{ textAlign: "center" }}>
                                <Link to="/formation"> <Button className="buttonWhoami"> Découvrez nos formations </Button> </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            <ContactModal show={show} handleClose={handleClose} />
        </Container>
    );
}

export default ExpertisePage;