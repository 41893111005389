
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { customPutApiCall, customGetApiCall, customDeleteApiCall, customPostApiCall } from '../misc/helper';
import "../css/uploadImg.css"
import bin from '../asset/img/bin.png';
import modify from '../asset/img/modify.svg';
import { BACK_URL } from "../misc/env";

const explications = {
    home: "Tes photos doivent être en 1920x1080, si possible en dessous de 4 ou 5Mo (Privilegier le JPG/JPEG)",
    gallery: "Tes photos doivent être en format carré, 600x600, si possible en dessous de 1 ou 2Mo (Privilegier le JPG/JPEG)",
    blog: "Ici tu peux creer tes news, prends d'abord une photo et rajoute lui une description, tu peux rajouter un lien aussi pour que la photo devienne cliquable. Tes photos doivent être en format carré, 600x600, si possible en dessous de 1 ou 2Mo (Privilegier le JPG/JPEG) (Tes articles seront affiches du plus recent au plus vieux sur la HomePage) (italique : <i></i>) (gras : <b></b>) (souligne : <u></u>) (lien : <a href='https://truc.fr'>Cliquez ici</a>) (saut de ligne : <br />)",
    formation: "Ici tu peux creer tes formations. Tu dois avoir des images rectangulaires, 1920x1080, si possible en dessous de 4 ou 5Mo (Privilegier le JPG/JPEG)",
    cgv: "1er : CGV Formation | 2EME : CGV Expertise | 3EME : CGV Artistique | 4EME : Mentions Legales | 5 EME : Tarifs des tableaux - Ajouter une image (n'importe laquelle) et ajouter le lien du google drive"
}

const ImgUpload = () => {

    const [type, setType] = useState(null);
    const [img, setImg] = useState([]);
    const [show, setShow] = useState(false);
    const [link, setLink] = useState(null);
    const [idImg, setIdImg] = useState(null);
    const [description, setDescription] = useState(null);
    const [title, setTitle] = useState(null);
    const [price, setPrice] = useState(null);
    const [available, setAvailable] = useState(null);

    const handleClose = () => setShow(false);
    const handleCloseAndSave = () => {
        setShow(false);
        customPostApiCall(`/file`, { id: idImg, link, description, title, price, available })
    }
    const handleShow = (id) => {
        const getOneImg = async (id) => {
            const img = await customGetApiCall(`/file/${id}`)
            setLink(img.data.files[0].link)
            setDescription(img.data.files[0].description)
            setIdImg(img.data.files[0].id)
            setShow(true);
            setTitle(img.data.files[0].title)
            setPrice(img.data.files[0].price)
            setAvailable(img.data.files[0].available)
        }
        getOneImg(id)
    }

    useEffect(() => {
        const fetchImg = async () => {
            const img = await customGetApiCall(`/file/type/${type}`)
            setImg(img.data.files)
        }
        fetchImg()
    }, [type]);

    const handleSubmitButton = (e) => {

        if (type != null) {

            const files = e.target.files;
            let newFiles = [];
            let promises = [];
            
            for (let i = 0; i < files.length; i++) {
                promises.push(customPutApiCall("/file", {
                    type,
                    file: files[i]
                }, true))
            }

            Promise.all(promises).then(res => {
                for (let d of res) {
                    newFiles.push(d.data.data)
                }
                let newImg = [...img, ...newFiles]
                setImg(newImg)
            })
            
        }
    }

    const handleSelectChange = (e) => {

        const value = e.target.value;

        if (value === "null") {
            setType(null);
        } else {
            setType(value);
        }
    }

    const handleDelete = (path) => {
        if (confirm('Es-tu sûre de vouloir supprimer cette image ?')) {
            customDeleteApiCall(`/file/${path}`).then(res => {
                const newImg = img.filter(img => img.path !== path)
                setImg(newImg)
            })
        }

    }

    return (
        <Container>
            <Form.Group>
                <Form.Label>Selectionne la page ou tu veux changer les images</Form.Label>
                <Form.Select aria-label="Default select example" onChange={handleSelectChange}>
                    <option value="null"> Choisis une page </option>
                    <option value="home">Home</option>
                    <option value="gallery">Gallery</option>
                    <option value="blog">Blog</option>
                    <option value="formation">formation</option>
                    <option value="cgv">CGV</option>
                </Form.Select>
            </Form.Group>
            <br></br>
            {
                type != null
                ? <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Label>Tu peux glisser tes images en dessous</Form.Label>
                    <Form.Control type="file" name="file" multiple onChange={handleSubmitButton}/>
                </Form.Group>
                : null
            }
            {
                type != null
                ? <Col className="explication">{explications[type]}</Col>
                : null
            }

            <Row>
                {
                    img.map((file, index) => {
                        return (
                            <Col className="uploadedImgContainer" xs={6} md={2} key={file.path}>
                                <img className="uploadedImg" key={index} src={`${BACK_URL}/images/${file.path}`} alt=""/>
                                <img className="binUploadedImg" src={bin} alt="" onClick={ () => {handleDelete(file.path)} } />
                                <img className="modifyImg" src={modify} alt="" onClick={ () => {handleShow(file.id)} } />
                            </Col>
                        )
                    })
                }
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modifier la description de l'image</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        (type === "formation")
                        ? <Form.Group controlId="formBasicPassword">
                            <Form.Label>Titre</Form.Label>
                            <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
                        </Form.Group> : null
                    }
                    {
                        (type === "formation")
                        ? <Form.Group controlId="formBasicPassword">
                            <Form.Label>Prix</Form.Label>
                            <Form.Control type="number" value={price} onChange={(e) => setPrice(e.target.value)}/>
                        </Form.Group> : null
                    }
                    {
                        (type === "formation")
                        ? <Form.Group controlId="formBasicPassword">
                            <Form.Label>Disponible maintenant ?</Form.Label>
                            <Form.Check type="checkbox" value={available} onChange={(e) => setAvailable(e.target.checked)}/>
                        </Form.Group> : null
                    }
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Lien de redirection (Non obligatoire)</Form.Label>
                        <Form.Control type="text" value={link} placeholder="lien" onChange={(e) => setLink(e.target.value)}/>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Description (Non obligatoire)</Form.Label>
                        <Form.Control as="textarea" value={description} rows={3} onChange={(e) => setDescription(e.target.value)}/>
                    </Form.Group>
                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Fermer sans modifer
                </Button>
                <Button variant="primary" onClick={handleCloseAndSave}>
                    Sauvegarder la modification
                </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default ImgUpload;

