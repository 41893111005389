import { useEffect, useState } from "react";
import { givePermission } from "../misc/helper";

import ImgUpload from "../components/ImgUpload";

const Admin = () => {

    useEffect(() => {
        givePermission((err) => {
            sessionStorage.removeItem("jwt");
            window.location.href = "/#/login";
        });
    });

    return (
        <div style={{ height: "100vh" }}>
            <h1 style={{ textAlign: "center", fontSize: 66 }}>Admin</h1>

            <ImgUpload />
        </div>
    )

}

export default Admin;