import { Button, Col, Container, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';

import "../css/whoami.css";
import mainImg from "../asset/img/marineBouvierRubelite.jpg";
import tanzaniteSquare from "../asset/img/about/diamant.png";
import { useEffect } from "react";

import pdf1 from "../asset/pdf/tanzanite.pdf"
import pdf2 from "../asset/pdf/couleur.pdf"

const WhoAmI = () => {

    useEffect(() => {
        return () => {
            window.scrollTo(0, 0);
        }
    })

    const tooltip = ( text ) => {

        const popover = (
            <Popover id="popover-basic">
              <Popover.Body>
                Consulter les mémoires : <br/>
                - <a className="popoverAbout" href={pdf1} rel="noopener noreferrer" target="_blank"> La tanzanite, merveille de Merelani (2018) ING</a><br/>
                - <a className="popoverAbout" href={pdf2} rel="noopener noreferrer" target="_blank">L'origine de la couleur dans les diamants de type IIB (2019) DUG de Nantes </a>
              </Popover.Body>
            </Popover>
          );

        return <OverlayTrigger trigger="focus" placement="top" overlay={popover}>
            <Button className="popoverBtn">
                <b>{ text }</b>
            </Button>
        </OverlayTrigger>

    }

    return (
        <Container className="mainContainer">

            <Row className="flexDirectionColumn textCenter">
                <Row>
                    <Col md={5} className="mainImgContainer">
                        <img src={mainImg} alt="mainImg" className="mainImg" />
                    </Col>
                    <Col md={7} className="marginAuto">
                        <Col className="title">Promouvoir l’excellence de la gemmologie française sous toutes ses facettes</Col>
                        <Col className="containerText">
                            <p>Passion, transmission, authenticité et excellence, telles sont les valeurs qui me poussent jour après jour à entreprendre en gemmologie en donnant le meilleur de moi-même.</p>
                            <p>Diplômée du titre experte-gemmologue de l’<b>Institut National de Gemmologie</b> et de la <b>FEEG (diplôme européen)</b> en 2018, j’ai immédiatement eu à cœur de pousser mes connaissances vers les plus hautes sphères scientifiques de la gemmologie. C’est ainsi que mes pas m’ont tout naturellement orienté vers le <b>diplôme universitaire de gemmologie (DUG) de Nantes</b>, un des seuls diplômes de gemmologie de laboratoire au monde.</p>
                            <p>
                                Mes études m’ont amenée à réaliser <b>plusieurs mémoires</b>, sur la tanzanite, les diamants de type IIB ou le calcul des formules structurales des séries continues, <b>que vous pouvez consultez librement en dessous</b>.<br/>
                                <a className="popoverAbout" href={pdf1} rel="noopener noreferrer" target="_blank"> La tanzanite, merveille de Merelani (2018) ING</a><br/>
                                <a className="popoverAbout" href={pdf2} rel="noopener noreferrer" target="_blank">L'origine de la couleur dans les diamants de type IIB (2019) DUG de Nantes </a>
                            </p>
                            <p>Ayant à cœur d’<b>explorer les différentes</b> facettes de la gemmologie, j’ai eu l’occasion de proposer des conférences et participer à de nombreux salons internationaux, visiter les pays producteurs de pierres et enseigner en école de gemmologie les programmes de la World Gem Foundation de 2019 à 2021.</p>
                        </Col>
                    </Col>
                </Row>
            </Row>


            <Row className="textCenter marginBot45 responsiveCont">
                <Col md={7} className="marginAuto">
                    <Col className="title">Une nouvelle vision de la gemmologie</Col>
                    <Col className="containerText">
                        <p>Forte de ces expériences, j’ai alors choisi de me lancer <b>à mon compte</b> afin de vous proposer mon expertise dans l’identification de vos pierres précieuses, fines et ornementales, mais aussi en vous proposant des formations en gemmologie, entièrement repensées pour <b>répondre aux besoins réels du métier</b>, mais aussi et surtout <b>à vos attentes et votre budget</b>.</p>
                        <p>Avec une collection personnelle de <b>plus de 1000 gemmes</b> patiemment sélectionnées pour l’apprentissage pratique & tous les <b>instruments de gemmologie</b> adaptés, j’ai réellement à cœur de <b>transmettre ma passion</b> avec rigueur et confiance, tout en <b>vous accompagnant</b> au maximum dans vos projets.</p>
                    </Col>
                </Col>
                <Col md={5} className="mainImgContainer">
                    <img src={tanzaniteSquare} alt="mainImg" className="mainImg" />

                </Col>
            </Row>
            <Row className="textCenter">
                <div className="mainParalax" />
            </Row>
            <Row className="flexDirectionColumn textCenter endPage">
                <Col className="title">Un monde entre Science &amp; Art</Col>
                <Row>
                    <Col className="containerText" md={{ span: 6, offset: 3}}>
                        <p>Afin de vous permettre d’admirer et de comprendre à votre tour la beauté de ce que j’observe quotidiennement au microscope, j’ai souhaité vous dévoiler via ma galerie intitulée « <b>Au Cœur des gemmes </b>», les plus belles photomicrographies de ma carrière, <b>toutes disponibles en impression sur demande</b>.</p>
                        <p>Cette galerie se veut la première du genre en France, ouverte à tous.tes et gratuitement consultable.</p>
                    </Col>
                    <Link to="/galerie"> <Button className="buttonWhoami"> Voir ma galerie </Button> </Link>
                </Row>
            </Row>
        </Container>
    )
}

export default WhoAmI;