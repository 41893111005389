import { Container, Row, Col, Button } from "react-bootstrap";
import banniere from "../asset/img/education/banniere.png";

import "../css/education.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ContactModal from "../components/ContactModal";
import { customGetApiCall } from "../misc/helper";
import { BACK_URL } from "../misc/env";
import { isMobile } from "react-device-detect";
import MailerModal from '../components/MailerModal';

const EducationPage = () => {
    const [formation, setFormation] = useState(null);
    const [show, setShow] = useState(false);
    const [showMailer, setShowMailer] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseMailer = () => setShowMailer(false);
    const handleShowMailer = () => setShowMailer(true);

    useEffect(() => {

        const fetchImg = async () => {
            let formations = await (await customGetApiCall(`/file/type/formation`)).data.files;
            formations = formations.map((formation) => {
                formation.path = `${BACK_URL}/images/${formation.path}`;
                return formation;
            })
            setFormation(formations)
        }
        if (formation == null)
            fetchImg()

        return () => {
            window.scrollTo(0, 0);
        }
    }, [])

    return (
        <Container style={{ padding: 0, background: 'white' }} className="mainContainer">

            <Row style={{ position: "relative", margin: 0, marginBottom: 30 }}>
                <img src={banniere} alt="EducationImage" className="expertiseImage" />
                <div className="overlayExpertise">
                    Formation
                </div>
            </Row>


            <Row>
                <Col className="textExpertiseTitle" md={{  span: 10, offset: 1}} xs={{ span: 10, offset: 1}}>
                    <p> Les cours proposés par Marine Bouvier permettent au grand public et aux professionnels de découvrir le monde des pierres ou de se perfectionner en gemmologie.</p>
                </Col>
                <Col className="textExpertise" md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1}}>
                    <p>La mise à disposition d’une grande collection de plus de 1000 références de pierres et de l’ensemble des instruments de gemmologie me permettent de vous proposer le meilleur dans l’apprentissage des pierres précieuses, fines et ornementales.</p>
                </Col>
                <Col className="textExpertise" md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1}}>
                    <p> Deux types de programmes vous sont proposés en fonction de votre profil et de vos possibilités de déplacement : </p>
                </Col>
            </Row>

            <Row style={{justifyContent: "space-evenly"}}>
                <Col md={{ span: 5}} xs={{ span: 10 }} className="containerPrice">
                
                    <div className="titleList">Les sessions en <b>DISTANCIEL</b>:</div>
                    <ul>
                        <li>Les formations en distanciel sont en cours de preparation et arrivent bientôt !</li>
                        <li>Pour etre averti en avant-première de leur mis en ligne, je vous invite à me suivre sur les réseaux sociaux & à vous inscrire à la Gemsletter</li>
                    </ul>
                    {/* <ul>
                        <li> Session d’une ou plusieurs <b>vidéos pré-enregistrées composées de :</b>
                            <ul>
                                <li><b>Diaporamas immersifs :</b> pour une théorie utile et facile à comprendre</li>
                                <li><b>Vidéos sous-microscope exclusives ! :</b> des vidéos ludiques réalisées à fort grossissement pour vous apprendre à identifier les inclusions en direct (un concept unique en France !)</li>
                                <li><b>Vidéos macro complémentaires :</b> des différentes pierres pour vous aider dans votre pratique </li>
                            </ul>
                        </li>
                        <li> Consultables <b>de chez vous</b> </li>
                        <li> Apprentissage <b>à votre rythme & visionnable plusieurs fois !</b></li>
                    </ul> */}

                </Col>
                <Col md={{ span: 5}} xs={{ span: 10 }} className="containerPrice">
                
                    <div className="titleList">Les sessions en <b>PRÉSENTIEL</b> :</div>
                    <ul>
                        <li> <b>Sessions courtes</b>, adaptées au monde professionnel </li>
                        <li> <b>Sessions personnalisées et individuelles</b> (maximum 2 personnes par cours)</li>
                        <li> Programmes mêlant <b>théorie utile et pratique très immersive</b></li>
                        <li> <b>Accompagnement</b> régulier et réponses aux questions </li>
                        <li> <b>Accueil sur Nice</b>, dans un endroit adapté à l’apprentissage </li>
                        <li> Délivrance d'un « <b>Certificat de Réalisation</b> » </li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col className="center" style={{marginBottom: "25px"}}><Button className="buttonWhoami" onClick={handleShowMailer}> Accéder en priorité à nos offres de lancement </Button></Col>
            </Row>
            <Row className="text-center">
                <Col style={{paddingBottom: 30}}>
                    Des programmes de <b>cours entièrement personnalisés</b> peuvent être réalisés sur demande, de même que des <b>conférences publiques ou privées</b>.<br />
                    Pour cela, n’hésitez pas à nous contacter <span className="linkEducation" onClick={handleShow}>ici</span><br />
                </Col>
            </Row>

            {
                isMobile
                    ? null
                    : <Row style={{ background: 'black', margin: 0, marginBottom: 30}}>
                        <div className="paralaxEducation" />
                    </Row>
            }

            <Row className="programmeTitle"><p>Nos programmes</p></Row>

            <Row style={{justifyContent: "space-evenly"}}>
                {
                    
                    formation ? formation.map((item) => {

                        let classA = item.available ? "containerEducation containerAvailable" : "containerEducation"

                        return (
                            <Col key={item.title} md={{ span: 5}} xs={{ span: 10, offset: 1 }} className={classA}>
                                <img src={item.path} className="imgFormation" />
                                <Col className="contEdu">
                                    <div className="titleEducation">{item.title}</div>
                                    <div className="description">{item.description}</div>
                                </Col>
                                {
                                    item.available
                                    ? <Row className="containerabs">
                                        <Col>
                                            <Button className="buttonEducation"><a className="linkEducation linkEducation2" href={item.link} target="_blank" rel="noreferrer">En savoir plus</a></Button>
                                        </Col>
                                        <Col className="price"><span>{ item.price } €</span></Col>
                                    </Row>
                                    : <Row>
                                        <Col className="available">Bientôt disponible</Col>
                                    </Row> 
                                }
                            </Col>
                        )
                    }) : null
                }
            </Row>

            <Row>
                <Col md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1}} style={{ marginBottom: 35 }}>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <Button className="buttonWhoami" onClick={handleShow}> Demander un devis ou des informations </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ContactModal show={show} handleClose={handleClose} />
            <MailerModal show={showMailer} handleClose={handleCloseMailer} />

        </Container>
    );
}

export default EducationPage;