import { Button, Modal } from "react-bootstrap"

const sendMail = (data) => {

    let email = "contact@marinebouvier.fr";
    let str = "";

    switch(data) {
        case 1:
            str = `mailto:${email}?subject=Demande%20de%20devis%20pour%20l%E2%80%99achat%20d%E2%80%99un%20tableau%20Gemmartistic&body=Bonjour%2C%0D%0A%0D%0APour%20recevoir%20un%20devis%20personnalis%C3%A9%20concernant%20l%E2%80%99impression%20d%E2%80%99un%20tableau%20Gemmartistic%2C%20veuillez%20nous%20renseigner%20les%20informations%20suivantes.%0D%0ANous%20ferons%20tout%20notre%20possible%20pour%20vous%20r%C3%A9pondre%20au%20plus%20vite.%0D%0A%0D%0APour%20rappel%2C%20tous%20nos%20tableaux%20sont%20r%C3%A9alis%C3%A9s%20en%20aluminium%20dibond%20de%203%20mm%20d%E2%80%99%C3%A9paisseur%2C%20anti-reflet%2C%20mati%C3%A8re%20hautement%20qualitative%20utilis%C3%A9e%20pour%20les%20expositions%20de%20photographies%20professionnelles.%0D%0APour%20plus%20de%20pr%C3%A9cisions%2C%20nous%20vous%20invitons%20%C3%A0%20consulter%20les%20%C2%AB%20CGV%20Gemmartistic%20%C2%BB.%0D%0A%0D%0A1.%20Votre%20nom%2C%20pr%C3%A9nom%20et%20nom%20de%20soci%C3%A9t%C3%A9%20(si%20professionnel)%20%3A%20(ins%C3%A9rer)%0D%0A%0D%0A2.%20Nom%20du%20tableau%20ou%20num%C3%A9ro%20de%20galerie%20de%20la%20photomicrographie%20choisie%20%3A%20(ins%C3%A9rer)%0D%0A%0D%0A3.%20Dimensions%20(en%20centim%C3%A8tres)%20%3A%20(ins%C3%A9rer)%0D%0AAttention%2C%20toutes%20les%20photomicrographies%20de%20la%20galerie%20sont%20au%20format%20carr%C3%A9.%20Nous%20pouvons%20imprimer%20des%20tableaux%20allant%20de%2020%20%C3%A0%20130%20centim%C3%A8tres%20de%20c%C3%B4t%C3%A9.%0D%0ASi%20vous%20souhaitez%20un%20format%20diff%C3%A9rent%2C%20n%E2%80%99h%C3%A9sitez%20pas%20%C3%A0%20nous%20donner%20les%20dimensions%20pr%C3%A9cises%2C%20nous%20pourrons%20vous%20proposer%20un%20recadrage%20personnalis%C3%A9.%0D%0A%0D%0A4.%20Adresse%20postale%20compl%C3%A8te%20%3A%20(ins%C3%A9rer)%0D%0AVeuillez%20nous%20communiquer%20votre%20adresse%20postale%20compl%C3%A8te%20pour%20faciliter%20l%E2%80%99estimation%20du%20devis.%0D%0A%0D%0A5.%20Autres%20informations%20ou%20demandes%20%3A%20(ins%C3%A9rer)%0D%0A%0D%0A`
            break;
        case 2:
            str = `mailto:${email}?subject=Demande%20de%20devis%20pour%20une%20expertise%20de%20pierres%20de%20couleur%20sur%20Nice%20et%20ses%20environs&body=Bonjour%2C%0D%0A%0D%0APour%20recevoir%20un%20devis%20personnalis%C3%A9%20concernant%20une%20expertise%2C%20veuillez%20nous%20renseigner%20les%20informations%20suivantes.%0D%0ANous%20ferons%20tout%20notre%20possible%20pour%20vous%20r%C3%A9pondre%20au%20plus%20vite.%0D%0A%0D%0APour%20rappel%2C%20nous%20nous%20d%C3%A9pla%C3%A7ons%20sur%20Nice%20et%20ses%20environs%20avec%20tous%20nos%20instruments%20et%20aucune%20pierre%20n%E2%80%99est%20conserv%C3%A9e%20par%20l%E2%80%99expert.%0D%0ALe%20tarif%20horaire%20est%20de%20180%E2%82%AC%20TTC%20par%20heure%20d%E2%80%99expertise%20et%20comprend%20l%E2%80%99analyse%20de%204%20pierres%20non%20mont%C3%A9es%20(ou%204%20pierres%20de%20centre)%20maximum.%20Un%20tarif%20suppl%C3%A9mentaire%20pour%20frais%20de%20d%C3%A9placement%20peut%20s%E2%80%99appliquer.%20Pour%20plus%20de%20pr%C3%A9cisions%2C%20nous%20vous%20invitons%20%C3%A0%20consulter%20les%20%C2%AB%20CGV%20Expertises%20%C2%BB.%0D%0A%0D%0A1.%20Votre%20nom%2C%20pr%C3%A9nom%20et%20nom%20de%20soci%C3%A9t%C3%A9%20(si%20professionnel)%20%3A%20(ins%C3%A9rer)%0D%0A%0D%0A2.%20Nombres%20exact%20de%20pierres%20%C3%A0%20expertiser%20%3A%20(ins%C3%A9rer)%0D%0AN%E2%80%99h%C3%A9sitez%20pas%20%C3%A0%20nous%20joindre%20des%20photos%20des%20pierres%20%C3%A0%20expertiser.%0D%0A%0D%0A3.%20Adresse%20postale%20compl%C3%A8te%20%3A%20(ins%C3%A9rer)%0D%0AVeuillez%20nous%20communiquer%20votre%20adresse%20postale%20compl%C3%A8te%20pour%20faciliter%20l%E2%80%99estimation%20du%20devis.%0D%0A%0D%0A4.%20Autres%20informations%20ou%20demandes%20%3A%20(ins%C3%A9rer)%0D%0A`
            break;
        case 3:
            str = `mailto:${email}?subject=Demande%20d%E2%80%99informations%20ou%20de%20devis%20personnalis%C3%A9%20concernant%20les%20cours%20de%20gemmologie%20&body=Bonjour%2C%0D%0A%0D%0ASi%20vous%20souhaitez%20recevoir%20de%20plus%20amples%20informations%20concernant%20un%20ou%20plusieurs%20cours%20de%20gemmologie%20(en%20pr%C3%A9sentiel%20ou%20en%20distanciel)%2C%20ou%20un%20devis%20personnalis%C3%A9%2C%20veuillez%20nous%20renseigner%20les%20informations%20suivantes.%0D%0ANous%20ferons%20tout%20notre%20possible%20pour%20vous%20r%C3%A9pondre%20au%20plus%20vite.%0D%0A%0D%0A%0D%0A1.%20Votre%20nom%2C%20pr%C3%A9nom%20et%20nom%20de%20soci%C3%A9t%C3%A9%20(si%20professionnel)%20%3A%20(ins%C3%A9rer)%0D%0A%0D%0A2.%20Nom%20du%2Fdes%20cours%20de%20gemmologie%20qui%20vous%20int%C3%A9resse%2Fnt%20%3A%20(ins%C3%A9rer)%0D%0A%0D%0A3.%20Votre%20demande%20%3A%20(ins%C3%A9rer)%0D%0ASi%20vous%20%C3%AAtes%20int%C3%A9ress%C3%A9.e%20par%20une%20formation%20en%20pr%C3%A9sentiel%20sur%20Nice%2C%20n%E2%80%99h%C3%A9sitez%20pas%20%C3%A0%20nous%20indiquer%20vos%20disponibilit%C3%A9s.%0D%0A`
            break;
        case 4:
            str = `mailto:${email}?subject=Autre%20demande%20d%E2%80%99informations%20&body=Bonjour%2C%0D%0A%0D%0ASi%20vous%20souhaitez%20nous%20contacter%20pour%20une%20autre%20demande%20d%E2%80%99informations%2C%20une%20proposition%20de%20collaboration%2C%20de%20conf%C3%A9rence%20ou%20d%E2%80%99exposition%2C%20veuillez%20nous%20renseigner%20les%20informations%20suivantes.%0D%0ANous%20ferons%20tout%20notre%20possible%20pour%20vous%20r%C3%A9pondre%20au%20plus%20vite.%0D%0A%0D%0A%0D%0A1.%20Votre%20nom%2C%20pr%C3%A9nom%20et%20nom%20de%20soci%C3%A9t%C3%A9%20(si%20professionnel)%20%3A%20(ins%C3%A9rer)%0D%0A%0D%0A2.%20Votre%20demande%20%3A%20(ins%C3%A9rer)%0D%0A`
            break;
    }

    window.open(str);
}

const ContactModal = ({ show, handleClose }) => {
    return (
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header>
                <Modal.Title>Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>
                Vous pouvez nous contacter à l’adresse mail : contact@marinebouvier.fr ou plus facilement via nos formulaires de contacts ci-dessous. 
            </p>
            <p> <b>Veuillez cliquer sur le lien qui correspond à votre demande :</b> </p>
            <ul>
                <li className="linkMail" onClick={ () => { sendMail(1) } }>Demande de devis pour l’<b>achat d’un tableau</b> de la galerie</li>
                <li className="linkMail" onClick={ () => { sendMail(2) } }>Demande de devis pour une <b>expertise</b> de pierres de couleur sur Nice et ses environs</li>
                <li className="linkMail" onClick={ () => { sendMail(3) } }>Demande d’informations ou de devis personnalisé concernant les <b>cours de gemmologie</b></li>
                <li className="linkMail" onClick={ () => { sendMail(4) } }><b>Autres demandes</b> d’informations (conférences, collaborations, expositions, autres)</li>
            </ul>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
                Fermer
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ContactModal;