import { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Gallery from "../components/Gallery";
import "../css/gallery.css";

const GalleryPage = () => {

    useEffect(() => {
        return () => {
            window.scrollTo(0, 0);
        }
    })

    return (
        <Container className="mainContainer">
            <Row>
                <h1 className="titleGallery">Au Cœur des gemmes</h1>
            </Row>
            <Gallery />
        </Container >
    )
}

export default GalleryPage;

