import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from 'react-router-dom';

import "../css/whoami.css";
import "../css/expertisePage.css";
import "../css/artisticPage.css";
import tanzaniteSquare from "../asset/img/Artistic/tableau.png";
import banniere from "../asset/img/Artistic/banniere1.png";
import { useEffect, useState } from "react";

import VideoHeliodore from "../asset/img/videoHeliodore.mp4"
import ContactModal from "../components/ContactModal";

import { isMobile } from "react-device-detect";
import { customGetApiCall } from "../misc/helper";

const ArtisticPage = () => {

    const [links, setLinks] = useState(null);

    useEffect(() => {

        const fetchLinks = async () => {
            let links = await (await customGetApiCall(`/file/type/cgv`)).data.files;
            links = links.map(link => link.link)
            setLinks(links)
        }
        if (links == null)
            fetchLinks()

        return () => {
            window.scrollTo(0, 0);
        }
    }, [])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const tooltip = ( text, textTooltip) => {

        const tooltip = (
            <Tooltip className="tooltip">
                {textTooltip}
            </Tooltip>
        )

        return <OverlayTrigger placement="top" overlay={tooltip}>
            <span className="popoverWord">
                { text }
            </span>
        </OverlayTrigger>

    }

    const defintions = [
        "Photomicrographie : désigne l’Art de prendre des photographies à fort grossissement à l’aide d’un microscope.",
        "Inclusions : véritables témoins de la croissance d’une gemme, elles représentent tout ce qui est observé à l’intérieur d’une pierre, et par extension à sa surface.",
        "Gemmologue : expert de l’identification des pierres précieuses, fines, ornementales et organiques."
    ]

    return (
        <Container className="mainContainer">

            <Row style={{ position: "relative", marginBottom: 30 }}>
                <img src={banniere} alt="ExpertiseImage" className="expertiseImage" />
                <div className="overlayExpertise">
                    Artistique
                </div>
            </Row>

            <Row className="flexDirectionColumn textCenter">
                <Row>
                    <Col md={7} className="marginAuto">
                        <Col className="title">UN CONCEPT ENTRE L’ART &amp; LA SCIENCE</Col>
                        <Col  className="containerLeft">
                            Aimant à la fois la <b>Science</b> pour son coté cartésien et pédagogique, mais aussi l’<b>Art</b> pour son coté créatif et émotif, je ne pouvais concevoir mon activité de gemmologue sans <b>allier l’une et l’autre</b>.
                            A travers mes { tooltip("photomicrographies", defintions[0]) }, je souhaite mettre en avant ces deux facettes qui se complémentent parfaitement.<br/><br/>
                            D’un côté, l’étude des { tooltip("inclusions", defintions[1]) } au microscope correspond à une compétence essentielle en gemmologie car elles sont les marqueurs de l’identification des pierres précieuses, mais aussi de leurs formations, d’une origine naturelle ou synthétique ou d’un traitement.
                            Elles constituent une base essentielle de l’apprentissage et du travail quotidien d’un { tooltip("gemmologue", defintions[2]) }.<br/><br/>
                            De l’autre, un travail minutieux me permet de <b>révéler l’infinité de formes, de textures et de couleurs</b> du monde intérieur des pierres.
                            Ce savant mélange de lumières, de techniques, de <b>patience et de passion</b> me permet de proposer des photographies <b>uniques</b> et stupéfiantes au moyen d’un authentique regard artistique.<br/><br/>
                            En travaillant sur leur composition et leur <b>beauté</b>, je cherche à déclencher <b>l’émotion</b> qui permettra de susciter un véritable <b>coup de cœur</b> afin de pouvoir être exposée en tant que tableaux précieux.<br/>
                        </Col>
                    </Col>
                    <Col md={5} className="mainImgContainer">
                        <div className="videoOuter">
                            <video className="video" autoPlay loop muted>
                                <source src={VideoHeliodore} type="video/mp4" />
                            </video>
                        </div>
                    </Col>
                </Row>
            </Row>

            <Row>
                <Col md={{ span: 8, offset: 2 }} className="artisticCadre">
                    <Col style={{ marginBottom: 15}}>L’impression de tableaux précieux Gemmartistic c’est :</Col>

                    <ul>
                        <li>
                            <b>Des tableaux personnalisables : </b><br />
                            Vous pouvez choisir l’une des photomicrographies de notre <Link className="linkArtistic" to="/galerie">galerie</Link>, de notre <a className="linkArtistic" href="https://www.etsy.com/fr/shop/Gemmartistic" rel="noopener noreferrer" target="_blank">boutique en ligne</a>, ou bien choisir de faire photographier l’une de vos pierres ou l’un de vos bijoux (nous contacter)
                        </li>
                        <li>
                            <b>Un concept unique entre Art & Science :</b><br />
                            Tableaux de photomicrographies d’inclusions au cœur des pierres précieuses
                        </li>
                        <li>
                            <b>Une matière qualitative :</b><br />
                            En aluminium dibond d’épaisseur 3 millimètres, anti-reflet, imprimé en France. Dimensions carrées allant de 20 centimètres à 1,30 mètre de côté. 
                        </li>
                        <li>
                            <b>Un délai ajusté :</b><br />
                            De 2 à 4 semaines pour la livraison (à partir de la validation de l’impression)
                        </li>
                        <li>
                            <b>Une attention aux détails et à la qualité : </b><br />
                            Un soin particulier est apporté lors de l’impression et de l’emballage
                        </li>
                        <li>
                            <b>Collaborations ou expositions :</b><br />
                             Si vous souhaitez une collaboration ou une exposition d’œuvres, contactez-nous.
                        </li>
                    </ul>
                    <div className="center"><Button className="buttonWhoami"> <a className="linkArtistic linkArtistic2" href={ links ? links[4] : ""} rel="noopener noreferrer" target="_blank">Consulter nos tarifs</a> </Button></div>

                </Col>
            </Row>


            <Row className="textCenter marginBot45 responsiveCont">
                <Col md={5} className="mainImgContainer">
                    <img src={tanzaniteSquare} alt="mainImg" className="mainImg2" />
                </Col>
                <Col md={7} className="marginAuto">
                    <Col className="title">L’HUMAIN ET LE SAVOIR FAIRE DERRIÈRE LES TABLEAUX</Col>
                    <Col className="containerRight">
                        J’ai à cœur de transmettre l’idée que chaque photographie correspond à <b>l’action conjuguée de nombreuses personnes</b> sans lesquelles tout cela ne pourrait exister. 
                        Dans notre monde de surconsommation, il me parait essentiel de comprendre que les <b>gemmes ne sont pas des produits que l’on trouve en masse</b>, et qu’elles sont soumis aux aléas humains, matériels et naturels.<br /><br />
                        Ainsi il me paraît évident et essentiel de travailler avec des <b>négociants</b>, principalement français, qui partent directement dans les pays producteurs, et qui ont pour <b>éthique de rémunérer correctement les mineurs</b> auxquels ils achètent les pierres qui me serviront ensuite de support pour mes tableaux. Je sélectionne également moi-même mes pierres dans les pays producteurs ou les bourses aux minéraux internationales.<br /><br />
                        Pour l’impression je fais appel à une grande <b>entreprise lyonnaise</b> spécialisée dans l’impression numérique, me permettant de continuer à mettre en avant le <b>savoir-faire français</b> autant que possible.
                        Nos tableaux sont réalisés en aluminium dibond d’épaisseur 3 millimètres et anti-reflet, ce qui correspond au support le plus qualitatif utilisé pour les expositions photos. <br />
                    </Col>
                </Col>
            </Row>
            
            {
                isMobile
                    ? null
                    : <Row className="textCenter">
                        <div className="paralaxRubis" />
                    </Row>
            }
            <Row className="flexDirectionColumn textCenter endPage">
                <Col className="title">Comment choisir et commander un tableau ?</Col>
                <Row style={{marginBottom: 30}}>
                    <Col className="containerText" md={{ span: 8, offset: 2}}>

                    Afin de vous permettre de trouver votre tableau coup de cœur, nous vous proposons une grande variété de choix et une liberté de personnalisation. Ainsi vous pouvez retrouver mes tableaux : <br /><br />
                    <ul>
                        <li>
                            <b>Sur la boutique en ligne : </b><br />
                            Retrouvez une selection déjà préconçue de tableaux en vente <a className="linkArtistic" href="https://www.etsy.com/fr/shop/Gemmartistic" rel="noopener noreferrer" target="_blank">ici</a>
                        </li>
                        <li>
                            <b>Dans ma galerie : </b><br />
                            Choisissez votre photo coup de coeur <Link className="linkArtistic" to="/galerie" target="_blank" rel="noopener noreferrer" >ici</Link> que vous pourrez personnaliser sur demande (Taille, couleur du cadre).
                        </li>
                        <li>
                            <b>Sur mesure : </b><br />
                            Vous pouvez également choisir de faire photographier l’une de vos pierres ou l’un de vos bijoux sur demande en me <span className="linkArtistic" onClick={handleShow}>contactant</span>.
                        </li>
                    </ul>
                    <b>Pour commander un tableau ou toute autre demande n'hésitez pas à me contacter, je me ferai un plaisir de vous répondre dans les plus brefs délais.</b>
                    </Col>
                </Row>
                <Col xs={{ span: 12, offset: 0}} md={{ span: 12, offset: 0}} style={{textAlign: 'center' }}><Button className="buttonWhoami" onClick={handleShow}> Contact </Button></Col>
            </Row>
            <ContactModal show={show} handleClose={handleClose} />
        </Container>
    )
}

export default ArtisticPage;