
import { Button, Modal } from "react-bootstrap"

import { isMobile } from "react-device-detect";

const MailerModal = ({ show, handleClose }) => {
    
    const css = isMobile ? "modalMobile" : "modalDesktop";

    return (
        <Modal show={show} onHide={handleClose} animation={false} dialogClassName={`${css}`}>
            <Modal.Body>
                {
                    isMobile
                    ? <iframe src="https://assets.mailerlite.com/jsonp/182129/forms/mA8MS5/content?v=1668363675" frameBorder="0" style={{position: "relative", inset: "0px", width: "331px", height: "870px", display: "block", zIndex: "10001", marginTop: "0px"}}></iframe>
                    : <iframe src="https://assets.mailerlite.com/jsonp/182129/forms/mA8MS5/content?v=1668363675" frameBorder="0" style={{position: "relative", inset: "0px", width: "100%", height: "720px", display: "block", zIndex: "10001", marginTop: "0px"}}></iframe>
                }
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
                Fermer
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MailerModal;